import { ComponentType, lazy, LazyExoticComponent } from 'react';
import * as React from 'react';
import RouteStructure from '../types/RouteStructure';
import { User } from '../factories/User';
import JsonObject from '../types/JsonObject';
import { Page } from '../factories/Page';
import { Interest } from '../factories/Interest';
import { Menu } from '../factories/Menu';
import { Profession } from '../factories/Profession';
import { Role } from '../factories/Role';
import { TableCell } from '../components/Table';
import DefaultTable from '../tables/DefaultTable';
import UserTable from '../tables/UserTable';
import pluralize from 'pluralize';
import InterestTable from '../tables/InterestTable';
import buildUrl from '../services/build-url';
import FormProps from '../forms/FormProps';
import { EducationalResource } from '../factories/EducationalResource';
import ProfessionTable from '../tables/ProfessionTable';
import { Module } from '../factories/Module';
import ModuleTable from '../tables/ModuleTable';
import { Stream } from '../factories/Stream';
import { News } from '../factories/News';
import { Group } from '../factories/Group';
import PageTable from '../tables/PageTable';
import MailTemplateTable from '../tables/MailTemplateTable';
import TrackingTable from '../tables/TrackingTable';
import GraduatesTable from '../tables/GraduatesTable';
import FinalExamsTable from '../tables/FinalExamsTable';
import EventsTable from '../tables/EventsTable';

interface CrudConfig<T> {
  path: string;
  title: string;
  listEndpoint: string;
  model: (data: JsonObject) => T;
  cells?: TableCell<any>[];
  createEndpoint?: string;
  modifyEndpoint?: string;
  primaryKey?: string;
  form?: LazyExoticComponent<ComponentType<FormProps>>;
  disableDelete?: boolean;
  disableEdit?: boolean;
  clientModifyPath?: string;
  toggleActive?: boolean;
  isSearchable?: boolean;
  isSortable?: boolean;
  backendClassName?: string;
  disabledForm?: (arg: any) => boolean;
  isSoftDeleted?: boolean;
  addNewUrl?: string;
  pageOverrides?: {
    create?: LazyExoticComponent<any>;
  };
}

export default class Crud<T = Object> {
  static USERS = new Crud({
    path: 'users',
    title: 'Users',
    listEndpoint: 'api/users',
    createEndpoint: 'api/users',
    modifyEndpoint: 'api/users/:id',
    model: User,
    cells: UserTable,
    form: lazy(() => import('../forms/admin/UsersForm')),
    isSearchable: true,
    isSoftDeleted: true,
  });

  static PAGES = new Crud({
    path: 'pages',
    title: 'Pages',
    listEndpoint: 'api/pages',
    createEndpoint: 'api/pages',
    modifyEndpoint: 'api/pages/:slug',
    cells: PageTable,
    model: Page,
    primaryKey: 'slug',
    form: lazy(() => import('../forms/admin/PagesForm')),
    clientModifyPath: '/:slug',
    isSoftDeleted: true,
  });

  static INTERESTS = new Crud({
    path: 'interests',
    title: 'Interests',
    listEndpoint: 'api/interests',
    createEndpoint: 'api/interests',
    modifyEndpoint: 'api/interests/:id',
    model: Interest,
    cells: InterestTable,
    form: lazy(() => import('../forms/admin/InterestsForm')),
  });

  static MENUS = new Crud({
    path: 'menus',
    title: 'Menus',
    listEndpoint: 'api/menus',
    modifyEndpoint: 'api/menus/:key',
    primaryKey: 'key',
    model: Menu,
    form: lazy(() => import('../forms/admin/MenusForm')),
    disableDelete: true,
  });

  static PROFESSIONS = new Crud({
    path: 'professions',
    title: 'Professions',
    listEndpoint: 'api/professions',
    createEndpoint: 'api/professions',
    modifyEndpoint: 'api/professions/:id',
    model: Profession,
    cells: ProfessionTable,
    form: lazy(() => import('../forms/admin/ProfessionsForm')),
  });

  static ROLES = new Crud({
    path: 'roles',
    title: 'Roles',
    listEndpoint: 'api/roles',
    createEndpoint: 'api/roles',
    modifyEndpoint: 'api/roles/:id',
    model: Role,
    form: lazy(() => import('../forms/admin/RolesForm')),
    isSortable: true,
    backendClassName: `App\\Role`,
  });

  static LEARNING_MODULES = new Crud({
    path: 'modules',
    title: 'Learning Modules',
    listEndpoint: 'api/modules',
    createEndpoint: 'api/modules',
    modifyEndpoint: 'api/modules/:slug',
    model: Module,
    primaryKey: 'slug',
    cells: ModuleTable,
    form: lazy(() => import('../forms/admin/ModulesForm')),
    clientModifyPath: '/members/modules/:slug',
    toggleActive: true,
  });

  static EDUCATIONAL_RESOURCES = new Crud({
    path: 'educational-resources',
    title: 'Educational Resources',
    listEndpoint: 'api/educational-resources',
    createEndpoint: 'api/educational-resources',
    modifyEndpoint: 'api/educational-resources/:slug',
    model: EducationalResource,
    primaryKey: 'slug',
    form: lazy(() => import('../forms/admin/EducationalResourcesForm')),
    clientModifyPath: '/members/educational-resources/:slug',
  });

  static STREAMS = new Crud({
    path: 'streams',
    title: 'Streams',
    listEndpoint: 'api/streams',
    createEndpoint: 'api/streams',
    modifyEndpoint: 'api/streams/:slug',
    model: Stream,
    primaryKey: 'slug',
    form: lazy(() => import('../forms/admin/StreamsForm')),
    clientModifyPath: '/members/streams/:slug',
  });

  static NEWS = new Crud({
    path: 'news',
    title: 'News',
    listEndpoint: 'api/news',
    createEndpoint: 'api/news',
    modifyEndpoint: 'api/news/:slug',
    model: News,
    primaryKey: 'slug',
    form: lazy(() => import('../forms/admin/NewsForm')),
    clientModifyPath: '/members/news/:slug',
  });

  static GROUPS = new Crud({
    path: 'groups',
    title: 'Groups',
    listEndpoint: 'api/groups',
    createEndpoint: 'api/groups',
    modifyEndpoint: 'api/groups/:slug',
    model: Group,
    primaryKey: 'slug',
    form: lazy(() => import('../forms/admin/GroupsForm')),
  });

  static GRADUATES = new Crud({
    path: 'fellowship-graduates',
    title: 'Graduates',
    listEndpoint: 'api/fellowship-graduates',
    createEndpoint: 'api/fellowship-graduates',
    modifyEndpoint: 'api/fellowship-graduates/:id',
    model: (data) => data,
    primaryKey: 'id',
    cells: GraduatesTable,
    form: lazy(() => import('../forms/admin/GraduatesForm')),
  });

  static FINAL_EXAMS = new Crud({
    path: 'final-exams',
    title: 'Final exams',
    listEndpoint: 'api/final-exams',
    createEndpoint: 'api/final-exams',
    modifyEndpoint: 'api/final-exams/:id',
    model: (data) => data,
    primaryKey: 'id',
    cells: FinalExamsTable,
    form: lazy(() => import('../forms/admin/FinalExamForm')),
  });

  static EVENTS = new Crud({
    path: 'events',
    title: 'Events',
    listEndpoint: 'api/events',
    createEndpoint: 'api/events',
    modifyEndpoint: 'api/events/:id',
    model: (data) => data,
    primaryKey: 'id',
    cells: EventsTable,
    form: lazy(() => import('../forms/admin/EventsForm')),
  });

  static MAILER = new Crud({
    path: 'mailer',
    title: 'Mailer',
    listEndpoint: 'api/mail-templates',
    createEndpoint: 'api/mail-templates',
    modifyEndpoint: 'api/mail-templates/:id',
    model: (data) => data,
    primaryKey: 'id',
    cells: MailTemplateTable,
    form: lazy(() => import('../forms/admin/MailTemplateForm')),
    disabledForm: (data: any) => {
      return data.sent_at != null;
    },
  });

  static TRACKING = new Crud({
    path: 'tracking',
    title: 'Tracking',
    listEndpoint: 'api/tracking',
    modifyEndpoint: 'api/tracking/:id',
    createEndpoint: 'api/tracking',
    model: (data) => data,
    disableEdit: true,
    primaryKey: 'id',
    cells: TrackingTable,
    isSearchable: true,
    form: lazy(() => import('../forms/admin/TrackingForm')),
    pageOverrides: {
      create: lazy(() => import('../screens/admin/Tracking/track')),
    },
  });

  private _path: string;
  private _title: string;
  private _listEndpoint: string;
  private primaryKey: string;
  private _model: (data: JsonObject) => T;
  private _cells: TableCell<any>[] | undefined;
  public clientModifyPath: string | undefined;
  public createEndpoint: string | undefined;
  public modifyEndpoint: string | undefined;
  public form: React.LazyExoticComponent<React.ComponentType<FormProps>> | undefined;
  public disableDelete: boolean;
  public disableEdit: boolean;
  public toggleActive: boolean;
  public isSearchable: boolean;
  public isSortable: boolean;
  public backendClassName?: string;
  public disabledForm?: (arg: any) => boolean;
  public isSoftDeleted: boolean;
  public pageOverrides: CrudConfig<T>['pageOverrides'];

  constructor(data: CrudConfig<T>) {
    this._path = data.path;
    this._title = data.title;
    this._listEndpoint = data.listEndpoint;
    this._model = data.model;
    this._cells = data.cells;
    this.primaryKey = data.primaryKey ?? 'id';
    this.createEndpoint = data.createEndpoint;
    this.modifyEndpoint = data.modifyEndpoint;
    this.form = data.form;
    this.disableDelete = data.disableDelete ?? false;
    this.disableEdit = data.disableEdit ?? false;
    this.clientModifyPath = data.clientModifyPath;
    this.toggleActive = data.toggleActive ?? false;
    this.isSearchable = data.isSearchable ?? false;
    this.isSortable = data.isSortable ?? false;
    this.backendClassName = data.backendClassName;
    this.disabledForm = data.disabledForm;
    this.isSoftDeleted = data.isSoftDeleted ?? false;
    this.pageOverrides = data.pageOverrides;
  }

  get title() {
    return this._title;
  }

  get path() {
    return this._path;
  }

  get modelNamePlural() {
    return pluralize(this._model.name.toLowerCase());
  }

  get model() {
    return this._model;
  }

  get modelName() {
    return this._model.name.toLowerCase();
  }

  get listEndpoint() {
    return this._listEndpoint;
  }

  get modifyPath() {
    return `${this.path}/edit/:${this.primaryKey}`;
  }

  get createPath() {
    return `${this.path}/create`;
  }

  get Routes(): RouteStructure[] {
    const routes: RouteStructure[] = [
      {
        name: `admin.${this._title.toLowerCase()}`,
        breadcrumbs: ['admin.dashboard'],
        path: this._path,
        exact: true,
        title: `${this.title} List`,
        component: lazy(() => import('../components/CrudList')),
        render: (Component) => {
          return <Component crud={this} />;
        },
      },
    ];

    if (this.modifyEndpoint) {
      routes.push({
        name: `admin.${this._title.toLowerCase()}.edit`,
        breadcrumbs: ['admin.dashboard', `admin.${this._title.toLowerCase()}`],
        path: this.modifyPath,
        exact: true,
        title: `Edit ${this.title}`,
        component: lazy(() => import('../components/CrudModify')),
        render: (Component) => {
          return <Component crud={this} />;
        },
      });
    }

    if (this.createEndpoint) {
      const route: any = {
        name: `admin.${this._title.toLowerCase()}.create`,
        breadcrumbs: ['admin.dashboard', `admin.${this._title.toLowerCase()}`],
        path: this.createPath,
        exact: true,
        title: `Create ${this.title}`,
      };

      if (this.pageOverrides?.create) {
        route.component = this.pageOverrides?.create;
      } else {
        route.component = lazy(() => import('../components/CrudModify'));
        route.render = (Component: any) => {
          return <Component crud={this} />;
        };
      }

      routes.push(route);
    }
    return routes;
  }

  get cells() {
    return this._cells || DefaultTable;
  }

  buildModifyRoute(data: JsonObject) {
    return buildUrl(this.modifyPath, data);
  }

  buildContentRoute(data: JsonObject) {
    if (!this.clientModifyPath) return undefined;
    return buildUrl(this.clientModifyPath, data);
  }

  static values(): Crud[] {
    return Object.values(this);
  }

  static paths() {
    return Crud.values().map((crud) => ({
      title: crud.title,
      path: crud.path,
    }));
  }

  static routes() {
    return Crud.values().reduce<RouteStructure[]>((carry, crud) => {
      return [...carry, ...crud.Routes];
    }, []);
  }
}
