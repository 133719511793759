import { Suspense, useMemo, useState } from 'react';
import useRoutes from './routes';
import { BrowserRouter, generatePath, matchPath, Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { LoadingOverlay } from '../../components/Loading';
import { createBrowserHistory } from 'history';
import RouteStructure from '../../types/RouteStructure';
import { CurrentRouteContext, useCurrentRoute } from '../../contexts/CurrentRouteContext';
import { useAuth } from '../../contexts/AuthContext';
import JsonObject from '../../types/JsonObject';
import { useAuthState } from '../hooks/useAuthState';
import { Helmet } from 'react-helmet';
import { HelmetInterpolationContext, useHelmetInterpolation } from '../../contexts/HelmetInterpolaionContext';
import Mustache from 'mustache';
import Alert from '../../components/Alert';

const DEFAULT_TITLE = 'Canadian Stroke Consortium';

const HelmetWrapper = () => {
  const currentRoute = useCurrentRoute();
  const { data } = useHelmetInterpolation();

  const title = useMemo(() => {
    if (!currentRoute || !currentRoute.title) return DEFAULT_TITLE;
    if (data?.loading) return DEFAULT_TITLE;
    return Mustache.render(currentRoute.title, data);
  }, [currentRoute, data]);

  return (
    <Helmet>
      <title>{title}</title>
    </Helmet>
  );
};

export function RenderRoute(props: RouteStructure) {
  const { component: Component, name, children, render, ...routeProps } = props;
  const { user, error } = useAuth();
  const [data, setData] = useState<JsonObject>({});

  const predicateResults = useMemo(() => {
    return routeProps.predicates?.map((predicate) => predicate({ user })).filter((result) => result != null);
  }, [user, routeProps]);

  useAuthState(user);

  return (
    <Route
      {...routeProps}
      render={({ location }) => {
        if (routeProps.auth && !user) {
          const params = new URLSearchParams({
            redirect: location.pathname,
            errorMessage: window.btoa(error?.response?.data?.message ?? 'Server error.'),
          });

          return <Redirect to={`/login?${params.toString()}`} />;
        }

        if (predicateResults?.length) {
          return (
            <div className="w-screen h-screen flex items-center justify-center">
              <div className="w-96 my-4">
                <Alert type="error" className="shadow">
                  {predicateResults.join('\n')}
                </Alert>
              </div>
            </div>
          );
        }

        return (
          <HelmetInterpolationContext.Provider value={{ data, setData }}>
            <CurrentRouteContext.Provider value={props}>
              <HelmetWrapper />
              {render ? render(Component) : <Component />}
            </CurrentRouteContext.Provider>
          </HelmetInterpolationContext.Provider>
        );
      }}
    />
  );
}

export const history = createBrowserHistory();

export function RenderChildRoutes() {
  const route = useCurrentRoute();
  const mergePredicates = (predicates: any[] = []) => {
    return [...(route?.predicates ?? []), ...predicates];
  };
  return (
    <Switch>
      {route?.children?.map(({ predicates, ...route }, index) => (
        <RenderRoute key={index} predicates={mergePredicates(predicates)} {...route} />
      ))}
    </Switch>
  );
}

export const flattenRouteList = (routes: RouteStructure[]): RouteStructure[] => {
  return routes.reduce<RouteStructure[]>((list, route) => {
    const { children, ...rest } = route;
    return [...list, rest, ...(children ? flattenRouteList(children) : [])];
  }, []);
};

export function useRoute() {
  const routeList = useRoutes();
  const location = useLocation();
  const flatRouteList = flattenRouteList(routeList);
  return flatRouteList.find((route) =>
    matchPath(location.pathname, {
      path: route.path,
      exact: true,
    }),
  );
}

export function Routes() {
  const routeList = useRoutes();
  return (
    <Suspense fallback={<LoadingOverlay />}>
      <Switch>
        {routeList.map((route) => (
          <RenderRoute key={route.name} {...route} />
        ))}
      </Switch>
    </Suspense>
  );
}

export default function Router() {
  return (
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
  );
}

export function useRouteByName() {
  const routeList = useRoutes();
  return (name: string, params: JsonObject = {}) => {
    const flatRouteList = flattenRouteList(routeList);
    const path = flatRouteList.find((route) => route.name === name)?.path;

    if (!path) {
      return name;
    }

    return generatePath(path, params);
  };
}
