import { TableCell } from '../components/Table';
import { format } from 'date-fns';

const FinalExamsTable: TableCell<any>[] = [
  {
    label: 'Name',
    value: 'name',
  },
  {
    label: 'Start date',
    value: (data) => {
      return <div className="flex w-full">{format(new Date(data.start_date), 'yyyy-MM-dd')}</div>;
    },
  },
  {
    label: 'Active',
    value: (data) => {
      return <div className="flex w-full">{data.is_active ? 'Active' : 'In active'}</div>;
    },
  },
];

export default FinalExamsTable;
