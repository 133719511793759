import { lazy } from 'react';
import { createDialogWrapper } from 'dialog-manager-react';

const DIALOGS = {
  confirm: lazy(() => import('./ConfirmDialog')),
  modifyQuestion: lazy(() => import('./ModifyQuestionDialog')),
  menuItem: lazy(() => import('./MenuItemDialog')),
  userSelect: lazy(() => import('./UserSelectDialog')),
  createThread: lazy(() => import('./CreateThreadDialog')),
  renewMembership: lazy(() => import('./RenewMembershipDialog')),
  acceptTerms: lazy(() => import('./AcceptTermsDialog')),
  moveResource: lazy(() => import('./MoveResourceDialog')),
  editResource: lazy(() => import('./EditResourceDialog')),
  adminFellow: lazy(() => import('./AdminFellowDialog')),
  createFellowshipUser: lazy(() => import('./CreateFellowshipUserDialog')),
  createEpaForm: lazy(() => import('./CreateEpaFormDialog')),
  createFellowDialog: lazy(() => import('./CreateFellowDialog')),
  createFinalEvaluation: lazy(() => import('./CreateFinalEvaluationDialog')),
  viewMessageDialog: lazy(() => import('./ViewMessageDialog')),
  viewSegmentUsers: lazy(() => import('./ViewSegmentUsersDialog')),
  mailPreview: lazy(() => import('./PreviewMailerTemplate')),
  orders: lazy(() => import('./UserOrdersDialog')),
  inviteRoleToGroup: lazy(() => import('./InviteRoleToGroupDialog')),
  description: lazy(() => import('./DescriptionDialog')),
  // finalExamApplicationEdit: lazy(() => import('./ApplicationEditDialog')),
};

const { DialogManager, useDialogs } = createDialogWrapper(DIALOGS);

export { DialogManager, useDialogs };
