import { lazy, useMemo } from 'react';
import RouteStructure from '../../types/RouteStructure';
import Crud from '../../screens/Crud';
import { isNotInterim, userHasRole } from './predicates';
import { PAM_ROLES } from '../../models/User';

function pathJoin(...parts: string[]) {
  const separator = '/';
  const replace = new RegExp(separator + '{1,}', 'g');
  return parts.join(separator).replace(replace, separator);
}

function buildRoutes(routes: RouteStructure[], parent?: RouteStructure) {
  return routes.map((route) => {
    if (parent) {
      route.path = pathJoin(parent.path, route.path);
    }
    if (route.children) {
      route.children = buildRoutes(route.children, route);
    }
    return route;
  });
}

export default function useRoutes() {
  return useMemo<RouteStructure[]>(
    () =>
      buildRoutes([
        {
          name: 'login',
          path: '/login',
          component: lazy(() => import('../../screens/templates/MainTemplate')),
          children: [
            {
              name: 'login',
              title: 'Login',
              path: '',
              exact: true,
              component: lazy(() => import('../../screens/login/LoginScreen')),
            },
            {
              name: 'login.forgot-password',
              path: 'forgot-password',
              title: 'Forgot Password',
              exact: true,
              component: lazy(() => import('../../screens/login/ForgotPasswordScreen')),
            },
            {
              name: 'login.reset-password',
              path: 'reset-password',
              title: 'Reset Password',
              exact: true,
              component: lazy(() => import('../../screens/login/ResetPasswordScreen')),
            },
            {
              name: 'login.not-found',
              title: 'Not found',
              path: '*',
              component: lazy(() => import('../../screens/NotFoundScreen')),
              render: (Component) => <Component />,
            },
          ],
        },
        {
          name: 'emails',
          path: '/emails',
          component: lazy(() => import('../../screens/templates/MainTemplate')),
          title: 'Emails',
          auth: true,
          children: [
            {
              name: 'unsubscrbe',
              path: 'unsubscribe',
              title: 'Unsubscribe',
              exact: true,
              auth: true,
              component: lazy(() => import('../../screens/login/UnsubscribeScreen')),
            },
          ],
        },
        {
          name: 'register',
          path: '/register',
          component: lazy(() => import('../../screens/register/index')),
          title: 'Register',
        },
        {
          name: 'payments',
          path: '/payments',
          component: lazy(() => import('../../screens/templates/MainTemplate')),
          title: 'Payment',
          children: [
            {
              name: 'payment-status',
              path: 'status',
              component: lazy(() => import('../../screens/payments/status')),
              title: 'Payment status',
            },
          ],
        },
        {
          name: 'cnsf-register',
          path: '/cnsf-register',
          component: lazy(() => import('../../screens/register/CnsfRegister')),
          title: 'Register',
        },
        {
          name: 'admin',
          path: '/admin',
          auth: true,
          predicates: [userHasRole(['admin'])],
          title: 'Admin Dashboard',
          component: lazy(() => import('../../screens/templates/AdminTemplate')),
          children: [
            {
              name: 'admin.dashboard',
              title: 'Admin Dashboard',
              path: '',
              exact: true,
              component: lazy(() => import('../../screens/admin/Dashboard')),
            },
            ...Crud.routes(),
            {
              name: 'admin.tools',
              title: 'Admin Tools',
              path: 'admin-tools',
              exact: true,
              component: lazy(() => import('../../screens/admin/AdminTools')),
            },
            {
              name: 'admin.tool',
              title: 'Admin Tool',
              path: 'admin-tools/:toolName',
              exact: true,
              component: lazy(() => import('../../screens/admin/AdminTools/show')),
            },
            {
              name: 'admin.message',
              title: 'Message Tracker',
              path: 'messages',
              exact: true,
              component: lazy(() => import('../../screens/admin/Messages')),
            },
            {
              name: 'settings',
              title: 'Settings',
              path: 'settings',
              exact: true,
              component: lazy(() => import('../../screens/admin/Settings')),
            },
            {
              name: 'admin.fellowship',
              title: 'Fellowship Program',
              path: 'fellowship',
              exact: true,
              component: lazy(() => import('../../screens/admin/fellowship/FellowshipDashboard')),
            },
            {
              name: 'not-found',
              path: '*',
              component: lazy(() => import('../../screens/NotFoundScreen')),
              render: (Component) => <Component />,
            },
          ],
        },
        {
          name: 'members',
          path: '/members',
          auth: true,
          component: lazy(() => import('../../screens/templates/MainTemplate')),
          children: [
            {
              name: 'members',
              title: 'Members',
              path: '',
              exact: true,
              component: lazy(() => import('../../screens/main/MembersScreen')),
            },
            {
              name: 'pam-directory',
              title: 'Professional Association Members Directory',
              predicates: [userHasRole([...PAM_ROLES, 'admin']), isNotInterim],
              path: 'professional-association-directory',
              exact: true,
              component: lazy(() => import('../../screens/members/PamDirectory')),
            },
            {
              name: 'members.educational-resource',
              title: 'Educational Resource',
              path: 'educational-resources/:educationalResourceSlug/:category?',
              component: lazy(() => import('../../screens/members/EducationalResourcesScreen')),
            },
            {
              name: 'members.sfcp-graduates',
              title: 'Graduates',
              path: 'sfcp-graduates/:year?',
              component: lazy(() => import('../../screens/members/Graduates')),
            },
            {
              name: 'members.educational-resource.item',
              title: 'Educational Resource',
              path: 'educational-resource/:educationalResourceSlug/item/:resourceItemId',
              component: lazy(() => import('../../screens/members/EducationalResourceItem')),
            },
            {
              name: 'members.streams',
              title: 'Streams',
              path: 'streams',
              exact: true,
              component: lazy(() => import('../../screens/members/Streams/List')),
            },
            {
              name: 'members.streams.view',
              title: 'Stream',
              path: 'streams/:slug',
              exact: true,
              component: lazy(() => import('../../screens/members/Streams/View')),
            },
            {
              name: 'members.news.show',
              title: 'News',
              path: 'news/:slug',
              exact: true,
              component: lazy(() => import('../../screens/members/News/ShowNews')),
            },
            {
              name: 'profile',
              title: 'Edit Profile',
              path: 'profile',
              exact: true,
              component: lazy(() => import('../../screens/members/ProfileScreen')),
            },
            {
              name: 'renewal',
              title: 'Renew membership',
              path: 'renewal',
              exact: true,
              auth: true,
              component: lazy(() => import('../../screens/Renewal')),
            },
            {
              name: 'members.not-found',
              path: '*',
              component: lazy(() => import('../../screens/NotFoundScreen')),
              render: (Component) => <Component />,
            },
          ],
        },
        {
          name: 'groups.threads',
          title: 'Groups',
          path: '/groups',
          auth: true,
          component: lazy(() => import('../../screens/templates/MainTemplate')),
          children: [
            {
              name: 'groups.dashboard',
              title: '{{group.name}} - Posts',
              path: '/view/:slug',
              exact: true,
              component: lazy(() => import('../../screens/members/Groups/ThreadsScreen')),
            },
            {
              name: 'groups.directory',
              title: 'Group Directory',
              path: '/view/:slug/directory',
              exact: true,
              component: lazy(() => import('../../screens/members/GroupDirectory')),
            },
          ],
        },
        {
          name: 'fellowship',
          path: '/fellowship',
          auth: true,
          component: lazy(() => import('../../screens/templates/MainTemplate')),
          children: [
            {
              name: 'fellowship',
              title: 'Fellowship Certification',
              path: '',
              exact: true,
              component: lazy(() => import('../../screens/fellowship/FellowshipScreen')),
            },
            {
              name: 'fellowship.apply',
              title: 'Fellowship Certification Application',
              path: '/apply',
              exact: true,
              component: lazy(() => import('../../screens/fellowship/ApplicationScreen')),
            },
            {
              name: 'fellowship.payment',
              title: 'Fellowship Certification Payment',
              path: '/payment',
              exact: true,
              component: lazy(() => import('../../screens/fellowship/PaymentScreen')),
            },
            {
              name: 'fellowship.epa-form',
              title: 'EPA Form',
              path: '/epa-form/:id',
              exact: true,
              component: lazy(() => import('../../screens/fellowship/EpaFormScreen')),
            },
            {
              name: 'fellowship.epa-category',
              title: 'EPA Form',
              path: '/epa-forms',
              exact: true,
              component: lazy(() => import('../../screens/fellowship/EpaCategoryScreen')),
            },
            {
              name: 'fellowship.final-evaluation',
              title: 'Final Evaluation Form',
              path: '/final-evaluation/:id',
              exact: true,
              component: lazy(() => import('../../screens/fellowship/FinalEvaluationFormScreen')),
            },
            {
              name: 'fellowship.profile',
              title: '{{user.name}} - Profile',
              path: '/profiles/:id',
              exact: true,
              component: lazy(() => import('../../screens/fellowship/FellowshipProfileScreen')),
            },
            {
              name: 'fellowship.profile.reports',
              title: '{{user.name}} - Reports',
              path: '/profiles/:id/reports',
              exact: true,
              component: lazy(() => import('../../screens/fellowship/FellowshipReportsScreen')),
            },
            {
              name: 'fellowship.graduates',
              title: 'Graduates',
              path: '/graduates',
              exact: true,
              component: lazy(() => import('../../screens/fellowship/Graduates')),
            },
          ],
        },
        {
          name: 'events',
          title: 'Events',
          path: '/events',
          auth: true,
          component: lazy(() => import('../../screens/templates/MainTemplate')),
          children: [
            {
              name: 'events.register',
              title: '{{event.name}} - Register',
              path: '/:slug',
              exact: true,
              component: lazy(() => import('../../screens/events/RegisterForEvent')),
            },
          ],
        },
        {
          name: 'dashboard',
          path: '/',
          component: lazy(() => import('../../screens/templates/MainTemplate')),
          children: [
            {
              name: 'home',
              title: 'Canadian Stroke Consortium',
              path: '',
              exact: true,
              component: lazy(() => import('../../screens/main/HomeScreen')),
            },
            {
              name: 'contact-us',
              title: 'Contact Us',
              path: '/contact-us',
              component: lazy(() => import('../../screens/ContactUs')),
            },
            {
              name: 'search',
              title: 'Search',
              path: '/search',
              component: lazy(() => import('../../screens/Search')),
            },
            {
              name: 'page',
              title: '{{page.name}}',
              path: ':pageSlug',
              component: lazy(() => import('../../screens/Page')),
            },
          ],
        },
      ]),
    [],
  );
}
